<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card>

        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  @click="$router.push({name:'stock-inventory-items', params: { site_id: site ? site : null } })"
                >
                <feather-icon
                    icon="GridIcon"
                    class="mr-25"
                  />
                  <span>Items</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> Stock Inventory Form</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="3">
              
              <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="site" @change="siteChange" :disabled="disabledOptions">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="3">
              <b-form-group label="Shift" class="required">
                  <b-form-select v-model="shift" @change="itemData()" :disabled="disabledOptions">
                      <b-form-select-option value="" disabled>Select</b-form-select-option>
                      <b-form-select-option :value="shift._id" v-for="shift in shifts" :key="shift._id">{{shift.shift | capitalize}}</b-form-select-option>
                  </b-form-select>
              </b-form-group>                                
          </b-col>

          

          <b-col md="3" >

            <b-form-group
              label="Date"
              class="required"

            > 
              <b-form-datepicker
              
                v-model="date"
                :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                locale="en"
                @input="itemData()"
                :disabled="disabledOptions"
              />

                
            </b-form-group>

          </b-col>

          <b-col md="3">
              <b-form-group label="Submitted By" class="required">
                  <b-form-input autocomplete="off" placeholder="3 to 100 characters" v-model="submitted_by" :disabled="disabledOptions"/>
              </b-form-group>
          </b-col>

          <b-col md="3" v-if="this.site && shift != ''">
            <b-form-group
              label="Week"
              class="required"
            >
              <b-form-input                
                v-model="week"
                @input="calculateWeek"
                type="text"
                autocomplete="off"
                @keypress="isNumberWeek($event)"
                :disabled="disabledOptions"
              />
                
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="this.site && shift != ''">
            <b-form-group
              label="Start of Week"
              class="required"
            >
               <b-form-input v-model="start_week" type="text" autocomplete="off" @keypress="isNumberWeek($event)" :disabled="disabledOptions"/>                
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="this.site && shift != ''">
            <b-form-group
              label="End of Week"
              class="required"
            >
                <b-form-input v-model="end_week" type="text" autocomplete="off" @keypress="isNumberWeek($event)" :disabled="disabledOptions"/>            
            </b-form-group>
          </b-col>
        </b-row>
        
    
    </b-card>
    
    <b-form @submit="formSubmit">
    

    <b-card no-body class="mb-1" v-if="this.site && shift != ''">
      
        <b-table
              responsive
              show-empty
              ref = "refUserListTable"
              class = "position-relative bigTableFH"
              primary-key = "id"
              empty-text = "No records found"
              :fields = "tableColumns"
              :items = "items"
          >

          <template #cell(item)="items">              
              <p>{{items.item.item}}</p>         
          </template>

          <template #cell(chemical_name)="items">
            <p>{{items.item.chemical_name}}</p>         
          </template>

          <template #cell(vendor)="items">
            <p>{{items.item.vendor}}</p>
          </template>

          <template #cell(total_quantity)="items">
            <p>{{items.item.total_quantity.length > 0 ? items.item.total_quantity : 'N/A'}}</p>
          </template>

          <template #cell(location)="items">
            <p>{{items.item.location}}</p>         
          </template>

          <template #cell(delivery_frequency)="items">
            <p>{{items.item.delivery_frequency}}</p>
          </template>

          <template #cell(uom)="items">
            <p>{{items.item.uom}}</p>
          </template>

          <template #cell(safety_level)="items">
            <p>{{items.item.safety_level}}</p>
          </template>

          <template #cell(delivery_stock)="items">
              <b-form-input placeholder="" v-model="items.item.delivery_stock" autocomplete="off" @keypress="isNumber($event)"/>
          </template>          

          <template #cell(on_hand_stock)="items">
              <b-form-input placeholder="" v-model="items.item.on_hand_stock" autocomplete="off" @keypress="isNumber($event)"/>
          </template>

          <template #cell(total_stock)="items">
              <b-form-input placeholder="" v-model="items.item.total_stock" autocomplete="off" @keypress="isNumber($event)"/>
          </template>

        </b-table>
    </b-card>

    <b-card class="mt-1"  v-if="this.site && shift != ''">
      
      <!-- signature structure  -->
      <b-row>
          <b-col offset-md="3" md="3">
						<b-form-group label="Start Time" class="required">
							<flat-pickr
								v-model="start_time"
								class="form-control"
								:config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
							/>
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group label="End Time" class="required">
							<flat-pickr
								v-model="end_time"
								class="form-control"
								:config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i'}"
							/>
						</b-form-group>
					</b-col>

          <b-col cols="12" class="mb-2">
              <h4 class="text-center pb-2">Signature <span style="color:red">*</span></h4>
              <center>
                  <b-media class="" @click="openSignature('supervisor')" v-if="crew_signature.image == null">                                      
                      <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" rounded style="height: 200px; width: auto"/>

                      <div class="d-flex flex-wrap">
                          <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                      </div>
                  </b-media>
                  <img :src="crew_signature.image" v-if="crew_signature.image != null" class="rounded" style="background: #ffffff" width="200px" height="180">
              </center>
              
              <center>
                  <b-button size="sm" variant="danger" class="mt-1 px-2 py-1" v-if="crew_signature.image != null" @click="removeSign('supervisor')" :disabled="disabledOptions"> 
                      <feather-icon icon="XIcon" class="mediumSize text-white"/>
                  </b-button>
              </center>
          </b-col>
      </b-row>      

      <b-row>
        <b-col>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class = "mr-1"
            variant="outline-secondary"
            @click="goBack()"
          >
            Cancel
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mr-1"
            v-if="id != null && typeof $route.params.id == 'undefined'"
            @click="discardForm()"
          >
            Discard Draft
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            type="submit"
            
            @click="status='draft'"
            v-if="typeof $route.params.id == 'undefined'"
          >
            {{ id == null ? 'Save as Draft' : 'Update Draft'}}
          </b-button>

          

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mr-1"
            type="submit"
            @click="status='completed'"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Save' }}
          </b-button>

            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            v-if="typeof $route.params.id != 'undefined' && ledit == false"
            @click="openSignature('approver')"
          >
            {{ typeof $route.params.id == 'undefined' ? 'Submit' : 'Approve' }}
          </b-button>

        </b-col>
      </b-row>

    </b-card>

    <b-card class="mt-1 text-center" v-if="items.length == 0 && site && shift"> No Item(s) Found. </b-card>

    </b-form>

    <!-- signature modal  -->
    <b-modal
            id="signatureModel"
            ref="signatureModel"
            centered
            title="Signature"
            no-close-on-backdrop
            hide-footer
            @hide="hideSignature"
            @show="hideSignature"
          >
          
          <div v-if="show_default == true">
            <b-row>

              <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
                <center>
                  <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
                </center>

              </b-col>
            </b-row>
              <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          <b-col md="12">
            <b-form-group
              label="Remarks"
              class=""
            >
              <b-form-textarea
              placeholder=""
              rows="3"
              v-model="approve_remark"
              />
            </b-form-group>
          </b-col>
        </b-row>

            <b-row class="mt-2">

              <b-col md="6">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      @click="show_default = false"
                    >
                    <span class="text-nowrap">Add New Signature</span>
                  </b-button>
                </b-col>

                <b-col md="6">
                  <b-button
                      variant="warning"
                      class="mt-0 float-right w-100"
                      @click="saveDefault()"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>

          <div v-else>
            <b-row>
              <b-col md="12">
                <VueSignaturePad
                  id="signature"
                  width="100%"
                  height="300px"
                  ref="signaturePad"
                  :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
                />

              </b-col>
            </b-row>
          <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <date-picker v-model="approve_signature_time" 
              format="DD MMM YYYY HH:mm" 
              type="datetime"
              placeholder="Select"
              valueType="format"
              :clearable=false
              :confirm=true
              lang="en"
              :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkDatePicker' : 'lightDatePicker'"
              :disabled="$store.getters.currentUser.role == 'supervisor' || $store.getters.currentUser.role == 'operation_manager' || $store.getters.currentUser.role == 'admin' ? false : true"
              :disabled-date="disabledDate"
              ></date-picker>


          </b-col>
        </b-row>

        <b-row class="mt-2" v-if="typeof $route.params.id != 'undefined'">
          
          <b-col md="12">
            <b-form-group
                label="Remarks"
                class=""
              >
                <b-form-textarea
                  placeholder=""
                  rows="3"
                  v-model="approve_remark"
                />
              </b-form-group>
          </b-col>
        </b-row>

            <b-row class="mt-2">

              <b-col md="3" v-if="typeof $route.params.id == 'undefined'">
                <b-button
                    variant="primary"
                    class="mt-0 w-100"
                    @click="uploadSign()"
                  >
                  <span class="text-nowrap">Upload</span>
                </b-button>
              </b-col>

              <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

                  <b-button
                      variant="secondary"
                      class="mt-0 w-100"
                      v-if="having_default == true"
                      @click="show_default = true"
                    >
                    <span class="text-nowrap">Default</span>
                  </b-button>
                </b-col>


              <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">

                  <b-button
                      variant="danger"
                      class="mt-0 w-100"
                      @click="clearSignature"
                    >
                    <span class="text-nowrap">Clear</span>
                  </b-button>
                </b-col>

                <b-col :md="typeof $route.params.id != 'undefined' ? '4' : '3'">
                  <b-button
                      variant="warning"
                      class="mt-0 w-100"
                      @click="saveSignature"
                    >
                    <span class="text-nowrap">Submit</span>
                  </b-button>
                </b-col>
            </b-row>
          </div>
        </b-modal>

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb,BFormRadio, 
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import CrewSignature from './CrewSignature.vue';
import Bus from "../../../event-bus";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,CrewSignature,BBreadcrumb,BFormRadio, DatePicker
  },
  
  data() {
    return {
      //locations:[],
      
      date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      site:'',
      week:'',
      start_week:'',
      end_week:'',
      shifts:[],
      shift:'',
      sites:[],
      submitted_by : this.$store.getters.currentUser.full_name,
      

      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      
      id:null,
      items:[],
      status:'draft',
      
      
      openedSignModel:null,
      

      tableColumns:[
        { key: 'item', label: 'Items', sortable: false , thStyle:  {width: '9%'}},
        { key: 'chemical_name', label: 'Chemical Name', sortable: false , thStyle:  {width: '16%'}},
        { key: 'vendor', label: 'Vendor', sortable: false , thStyle:  {width: '7%'}},
        { key: 'total_quantity', label: 'Total Quantity', sortable: false , thStyle:  {width: '13%'}},
        { key: 'location', label: 'Location', sortable: false , thStyle:  {width: '8%'}},
        { key: 'delivery_frequency', label: 'Delivery Frequency', sortable: false , thStyle:  {width: '10%'}},
        { key: 'uom', label: 'UOM', sortable: false , thStyle:  {width: '5%'}},

        { key: 'safety_level', label: 'Safety Level', sortable: false , thStyle:  {width: '5%'}},
        { key: 'delivery_stock', label: 'Delivery Stock', sortable: false , thStyle:  {width: '9%'}},
        
        { key: 'on_hand_stock', label: 'On Hand Stock', sortable: false , thStyle:  {width: '9%'}},
        { key: 'total_stock', label: 'Total Stock', sortable: false , thStyle:  {width: '9%'}},

      ],

      start_time: null,
      end_time: null,

      // signature vars
          doc_icon: require('@/assets/images/doc.png'),
          crew_signature : {
              image:null,
              name:'',
              type: 'supervisor',
              default:'no'
          },
          openedSignModel:null,

          flag: false,
          tempSign : {
              image:null,
              name:'',
          },
          show_default:false,
          having_default:false,      
          disabledOptions:false,
           supervisorSign : {
            image:null,
            name:'',
            type: 'supervisor',
            default:'no'
        },
      approve_signature_time:null,
      approve_remark:'',
      ledit : false,
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1 && typeof this.$route.params.id == 'undefined') {
                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },

    formSubmit(e){
      e.preventDefault();

      if(this.status == 'draft'){
        this.actualForm();            
      } else {    
        this.decisionAlert('Are you sure want to submit this report ?')
        .then(result => {
          if (result.value) {
            this.actualForm();            
          }
        })
      }
    },

    actualForm(){
      return this.$store.dispatch(POST_API, {
        data:{
            site :this.site,
            shift :this.shift,
            date : this.date,
            submitted_by :this.submitted_by,
            week:this.week,
            start_week:this.start_week,
            end_week:this.end_week,
            items : this.items,
            status:this.status,
            id:this.id,
            supervisorSign : this.crew_signature,
            start_time : this.start_time, 
						end_time : this.end_time, 
            form_id:this.$route.params.id,
            approve_signature: this.supervisorSign,
            approve_signature_time: this.approve_signature_time,
            approve_remark: this.approve_remark,
            role:this.$store.getters.currentUser.role,
        },
        api : '/api/submit-stock-inventory-form'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              // this.showDismissibleAlert = true;

              // window.scrollTo(0,0);
              this.errorAlertCF()
          } else {
              this.showDismissibleAlert = false;

              var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                //this.site = this.$route.params.site_id;
                
                if (this.sites.length == 1) {
                  
                  this.site = this.sites[0]._id;
            
                }else{

                  this.site = '';
                }
                this.siteChange();

                //Bus.$emit('counter_update');
                
                if (data != null) {
                  window.open(data, '_blank');
                }

                localStorage.setItem('routeCFCheck','no');
                // this.$router.go(-1);
                //this.$router.push({ name:'custom-forms' })
                if(this.$route.params.id){
                  this.$router.push({name:'stock-inventory-form-report'})
                }else{

                  this.$router.push({ name:'custom-forms' })
                }
                  
              });
          }
      });
    },
    itemData(){

      if (this.site != '' && this.shift != '') {
        
        return this.$store.dispatch(POST_API, {
           data:{
             site  : this.site != '' ? this.site : null,
             shift : this.shift != '' ? this.shift : null,
            //  week  : this.week != '' ? this.week : null,
             date  : this.date,
           },
           api: '/api/get-stock-inventory-item-data'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                this.id = data.id;

                this.week = data.week;
                this.start_week = data.start_week;
                this.end_week = data.end_week;
                this.items = data.items;
                this.crew_signature = data.supervisorSign;
                this.start_time = data.start_time;
						    this.end_time = data.end_time;

                return this.items;
            }
        });

      }

    },

    calculateWeek(){
        this.start_week = this.week > 0 ? this.week - 1 : 0;
        this.end_week = this.week > 0 ? this.week : 0;
    },
    
    siteChange(){

      this.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
      
      this.items = [];
      this.shifts = [];
      this.shift = '';
      
      this.getShiftData();

      if (this.site != '' && this.shift != '') {
        /* this.start_week = this.week - 1;
        this.end_week = this.week; */
        this.itemData();
      }
      else {
        this.week = '';
        this.start_week = '';
        this.end_week = '';
      }
    },

    getShiftData(){
        return this.$store.dispatch(POST_API, {
            data:{
                site:this.site != '' ? this.site : null,
            },
            api: '/api/site-shifts'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.shifts = data;
                return this.shifts;
            }
        });
    },

    
    breadCrumb(){
      if(this.$route.params.id){

        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Reports',
        },{
          to:{name:'custom-forms-dashboard'},
          text: 'Form Dashboard',
        },{
          to:{name:'custom-forms-reports'},
          text:'Custom Forms'
        },{
          to:{name:'stock-inventory-form-report'},
          text:'Stock Inventory Form Report',
          
        },{
          to:null,
          text:'Edit',
          active:true
        }];
        return this.filterReportBreadCrum(item);

      }else{

        var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
        },{
          to:{name:'custom-forms'},
          text: 'Custom Forms',
        },{
          to:null,
          text:'Stock Inventory Form Report',   
          active:true             
        }];

      }

      
      return item;


    },

    goBack(){
				var msg = 'Do you want to Go back without saving your report?';
				this.decisionAlert(msg)
				.then(result => {
					if (result.value) {
						localStorage.setItem('routeCFCheck','no');
						if(this.$route.params.id){
							this.$router.push({name:'stock-inventory-form-report'})
						}else{
							this.$router.push({ name:'custom-forms' })
						}
					}
				})
			},
    
    discardForm(){
        var msg = 'Are you sure want to discard this form?';

        this.decisionAlert(msg)
        .then(result => {
            if (result.value) {
                return this.$store.dispatch(POST_API, {
                    data:{
                        id     : this.id,
                        status : 'deleted'
                    },
                    api : "/api/change-stock-item-status",
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;

                        this.showAlert();
                    } else {
                        localStorage.setItem('routeCFCheck','no');
                        this.successAlert()
                        .then(() => {
                          //  this.$router.go(-1);
                          this.$router.push({ name:'custom-forms' })
                        });
                    }
                });
            }
        })
        .catch(err => {
            console.log(err);
        })
    },

    isNumber($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        // only allow number 
        if (keyCode < 48 || keyCode > 57) {
            $event.preventDefault();
        }
    },
    
    isNumberWeek($event) {
        let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
        // only allow number 
        if ($event.target.value.length >= 2) {
					$event.preventDefault();
				}else if(keyCode < 48 || keyCode > 57){
					$event.preventDefault();
				}
    },

    // signature functions
      supervisorImageUpload(event){
          var input = event.target;
          var files = event.target.files

          if (input.files && input.files[0]) {
              var reader = new FileReader();
              reader.readAsDataURL(input.files[0]);
              var image = input.files[0];

              if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Please upload .jpg, .jpeg, .jpg images only',
                      showConfirmButton: false,
                      timer: 1500
                  });

                  this.$refs.supervisorSInput.value=null;

              } else if (image.size > 2097152) {
                  Swal.fire({
                      position: 'center',
                      icon: 'warning',
                      title: 'Maximum 2 MB files allowed to be upload.',
                      showConfirmButton: false,
                      timer: 1500
                  })
                  this.$refs.supervisorSInput.value=null;
              } else {
                  reader.onload = (e) => {
                      this.crew_signature.image = e.target.result;
                      this.crew_signature.name = image.name;
                      this.crew_signature.type =  'supervisor';
                      this.crew_signature.default = 'no';
                  }
              }
          }
      },
              
      removeSign(sign){
          this.crew_signature.image = null;
          this.crew_signature.name = '';
          this.crew_signature.default = 'no';
      },

      useDefault(){
        return this.$store.dispatch(POST_API, {
            data:{
                id:this.$store.getters.currentUser._id
            },
            api: '/api/get-default-signature'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  if (data == null || data.default_signature == null) {
                  
                  this.having_default = false;
                  
                  }else{

                  this.tempSign = {
                      image : data.default_signature,
                      name : 'siganture.png',
                  }

                  this.having_default = true;
                  }
              }
          });
      },

      uploadSign(){
          this.$refs['supervisorSInput'].click();
          this.$refs['signatureModel'].hide();
      },

      openSignature(type){
              
      this.approve_signature_time = moment(new Date()).tz('Asia/Singapore').format('DD MMM YYYY HH:mm');

          if (this.openedSignModel == null) {
              if (this.having_default == true) {
                  this.show_default = true;
              }else{
                  this.show_default = false;
              }
              this.$refs['signatureModel'].show();
              this.openedSignModel = type;
          }
      },

      hideSignature(){
          this.openedSignModel = null;
      },

      clearSignature(){
          this.$refs.signaturePad.clearSignature();
      },

      saveSignature(){
          const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            
            if (isEmpty) {
                Swal.fire({
                    position: 'center',
                    icon: 'warning',
                    title: 'Signature is required',
                    showConfirmButton: false,
                    timer: 1500
                })
             }else{
                if (this.openedSignModel == 'supervisor') {
                    this.crew_signature.image = data;
                    this.crew_signature.name = 'signature.png';
                    this.crew_signature.type = 'supervisor';
                    this.crew_signature.default = 'no';
                } else {
                    this.supervisorSign.image = data;
                    this.supervisorSign.name = 'siganture.png';
                    this.supervisorSign.type = 'supervisor';
                    this.supervisorSign.default = 'no';
                    this.status = 'approved';
                    this.actualForm();

                }
        
              this.$refs['signatureModel'].hide();
            }
      },

      saveDefault(){
          if (this.openedSignModel == 'supervisor') {
              this.crew_signature = {
                  image : this.tempSign.image,
                  name : 'signature.png',
                  default:'yes'
              }
          }else{
              this.supervisorSign = {
                  image : this.tempSign.image,
                  name : 'signature.png',
                  default:'yes',
                  type:'supervisor'
              }
              this.status = 'approved';
              this.actualForm();
          }      
          this.$refs['signatureModel'].hide();
      },
      getFormDetails(){
        /*localStorage.setItem('routeCFCheck','yes');*/

        return this.$store.dispatch(POST_API, {
             data:{
               id:this.$route.params.id
             },
             api: '/api/get-stock-inventory-report-detail'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  this.id  = data._id;
                  this.date  = data.checked_date;
                  this.site  = data.site;
                  this.submitted_by  = data.submitted_by;
                  this.shift  = data.shift;
                  this.checklistData  = data.checkListData;
                  this.status = data.status;
                  this.crew_signature = {
                      image   : data.signature,
                      name    : 'signature.png',
                      type    : 'supervisor',
                      default : 'yes'
                  };
                  this.start_time = data.start_time;
                  this.end_time = data.end_time;
                  this.week = data.week;
                  this.start_week = data.start_week;
                  this.end_week = data.end_week;
                  this.items = data.items;

                  var role = this.$store.getters.currentUser.role;

                  if (data.reject_to == role && ((data.level_one_user_role != role) && (data.level_two_user_role != role) && (data.level_three_user_role != role))) {
                      //this.disabledOptions = false;
                      this.ledit = true;
                  } else if(((role == 'administrator') || (role == 'admin')) && ( (data.level_user_status != null) && (data.level_user_status.role == ''))){
                      this.ledit = true;
                  }

                  this.getShiftData();
                  this.getBuildings();
              }
          });

      },
      disabledDate(date){
          return date < moment(new Date(this.date)).subtract(1,'days').tz('Asia/Singapore');
      },
    
  },
  mounted(){
    
    this.allSites();
    this.siteChange();
    this.useDefault();
    if(this.$route.params.id){
      this.disabledOptions = true;
      this.getFormDetails();
     }
    
  },

  watch: {
    '$data': {
      handler: function(newValue) {
          if(newValue.site && newValue.shift && newValue.week){
            localStorage.setItem('routeCFCheck','yes');
          } else {
            localStorage.setItem('routeCFCheck','no');
          }        
      },
      deep: true
    }
  }

  
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
